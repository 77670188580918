import { useState } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

import {
  CONFIRM_BUTTON_TYPE,
  CONFIRM_MODAL_TYPE,
  ConfirmModal,
} from "@shared/lib/components/common/molecules/modal/confirm-modal";
import { EditMarketingAgree } from "@/components/domain/my/profile/edit-marketing-agree";
import { EditPassword } from "@/components/domain/my/profile/edit-password";
import { EditPhoneNumber } from "@/components/domain/my/profile/edit-phone-number";
import { ProfileInfo } from "@/components/domain/my/profile/profile-info";
import { RequestWithdrawal } from "@/components/domain/my/profile/request-withdrawal";
import { useProfile } from "@/hooks/auth/use-profile";
import { AuthType } from "@shared/generated/api/model";
import { useTranslation } from "react-i18next";

const EDIT_TYPE = {
  PASSWORD: "PASSWORD",
  PHONE_NUMBER: "PHONE_NUMBER",
  MARKETING_AGREE: "MARKETING_AGREE",
  WITHDRAWAL: "WITHDRAWAL",
} as const;
type EditType = keyof typeof EDIT_TYPE;

export const MyProfileTemplate = () => {
  const { t } = useTranslation();
  const { data: profile } = useProfile();

  const [editType, setEditType] = useState<EditType | null>();
  const [isOpenWithdrawalConfirmModal, setIsOpenWithdrawalConfirmModal] =
    useState(false);

  const onEditCancel = () => {
    setEditType(null);
  };

  const onActiveEditPassword = () => {
    if (profile?.authType === AuthType.EMAIL) {
      setEditType(EDIT_TYPE.PASSWORD);
    }
  };

  const onActiveEditPhoneNumber = () => {
    setEditType(EDIT_TYPE.PHONE_NUMBER);
  };

  const onActiveEditMarketingAgree = () => {
    setEditType(EDIT_TYPE.MARKETING_AGREE);
  };

  const onOpenWithdrawalConfirmModal = () => {
    setIsOpenWithdrawalConfirmModal(true);
  };

  const onCloseWithdrawalConfirmModal = () => {
    setIsOpenWithdrawalConfirmModal(false);
  };

  const onConfirmWithdrawal = () => {
    setEditType(EDIT_TYPE.WITHDRAWAL);
  };

  return (
    <MyProfileTemplateContainer>
      <TitleContainer
        className={classNames({
          "display-none": !editType,
        })}
      >
        {!editType && t("edit_my_info")}
        {editType === EDIT_TYPE.PASSWORD && t("change_password")}
        {editType === EDIT_TYPE.PHONE_NUMBER && t("change_phone_number")}
        {editType === EDIT_TYPE.MARKETING_AGREE &&
          t("modify_marketing_consent")}
        {editType === EDIT_TYPE.WITHDRAWAL && t("account_withdrawal")}
      </TitleContainer>
      {!editType && (
        <ProfileInfo
          onActiveEditPassword={onActiveEditPassword}
          onActiveEditPhoneNumber={onActiveEditPhoneNumber}
          onActiveEditMarketingAgree={onActiveEditMarketingAgree}
          onOpenWithdrawalConfirmModal={onOpenWithdrawalConfirmModal}
        />
      )}

      {editType === EDIT_TYPE.PASSWORD && (
        <EditPassword onEditCancel={onEditCancel} />
      )}

      {editType === EDIT_TYPE.PHONE_NUMBER && (
        <EditPhoneNumber onEditCancel={onEditCancel} />
      )}

      {editType === EDIT_TYPE.MARKETING_AGREE && (
        <EditMarketingAgree onEditCancel={onEditCancel} />
      )}

      {editType === EDIT_TYPE.WITHDRAWAL && (
        <RequestWithdrawal onEditCancel={onEditCancel} />
      )}

      {isOpenWithdrawalConfirmModal && (
        <ConfirmModal
          type={CONFIRM_MODAL_TYPE.ERROR}
          confirmButtonType={CONFIRM_BUTTON_TYPE.TEXT}
          isOpen={isOpenWithdrawalConfirmModal}
          title={t("are_you_sure_you_want_to_withdraw")}
          subTitle={t("withdrawal_info")}
          confirmText={t("think_again")}
          cancelText={t("confirm_withdrawal")}
          onClose={onCloseWithdrawalConfirmModal}
          onConfirm={onCloseWithdrawalConfirmModal}
          onCancel={onConfirmWithdrawal}
        />
      )}
    </MyProfileTemplateContainer>
  );
};

const MyProfileTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  gap: 80px;

  ${({ theme }) => theme.media.tabletSm} {
    padding: 0 16px;
    gap: 40px;

    min-height: auto;
  }
`;

const TitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  ${({ theme }) => theme.media.tabletSm} {
    &.display-none {
      display: none;
    }
  }
`;
