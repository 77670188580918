import { useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import {
  getUserControllerGetProfileQueryKey,
  useUserControllerVerifyPassword,
} from "@shared/generated/api/fn/kac/user/user";
import { useTranslation } from "react-i18next";

interface EditPasswordForm {
  password: string;
  updatePassword: string;
  updateMatchPassword: string;
}

interface EditPasswordProps {
  onEditCancel: () => void;
}

export const EditPassword = ({ onEditCancel }: EditPasswordProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowUpdatePassword, setIsShowUpdatePassword] = useState(false);
  const [isShowUpdateMatchPassword, setIsShowUpdateMatchPassword] =
    useState(false);

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onShowUpdatePassword = () => {
    setIsShowUpdatePassword(!isShowUpdatePassword);
  };

  const onShowUpdateMatchPassword = () => {
    setIsShowUpdateMatchPassword(!isShowUpdateMatchPassword);
  };

  const { accessToken } = useAccessToken();
  const updatePasswordMutation = useUserControllerVerifyPassword({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, t("password_has_been_changed"));
        await queryClient.invalidateQueries({
          queryKey: getUserControllerGetProfileQueryKey(),
        });
        onEditCancel();
      },
      onError: (error) => {
        const { errorCode, message } = error?.response?.data as any;

        if (errorCode === 100019) {
          setError("password", {
            type: "ERROR",
            message: t("passwords_do_not_match"),
          });
        } else if (errorCode === 100033) {
          setError("updatePassword", {
            type: "ERROR",
            message: t("new_password_is_the_same_as_the_old_password"),
          });
        } else {
          openToast(TOAST_TYPE.ERROR, t("failed_to_change_password"));
        }
      },
    },
  });

  const schema = yup.object({
    password: yup
      .string()
      .min(8, t("password_must_be_8_to_20_characters"))
      .max(20, t("password_must_be_8_to_20_characters"))
      .matches(/[a-zA-Z0-9d~!@#$%^&*()_+=]$/, t("invalid_special_characters"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        t("pwd_must_use_combination"),
      )
      .trim(t("no_spaces_allowed"))
      .required(t("enter_password")),
    updatePassword: yup
      .string()
      .min(8, t("password_must_be_8_to_20_characters"))
      .max(20, t("password_must_be_8_to_20_characters"))
      .matches(/[a-zA-Z0-9d~!@#$%^&*()_+=]$/, t("invalid_special_characters"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        t("pwd_must_use_combination"),
      )
      .trim(t("no_spaces_allowed"))
      .required(t("enter_password")),
    updateMatchPassword: yup
      .string()
      .min(8, t("password_must_be_8_to_20_characters"))
      .max(20, t("password_must_be_8_to_20_characters"))
      .matches(/[a-zA-Z0-9d~!@#$%^&*()_+=]$/, t("invalid_special_characters"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        t("pwd_must_use_combination"),
      )
      .oneOf([yup.ref("updatePassword")], t("passwords_do_not_match"))
      .required(t("enter_password")),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<EditPasswordForm>({
    resolver: yupResolver<EditPasswordForm>(schema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<EditPasswordForm> = async (data) => {
    updatePasswordMutation.mutate({
      data: {
        password: data.password,
        newPassword: data.updatePassword,
      },
    });
  };

  const onError: SubmitErrorHandler<EditPasswordForm> = async (error) => {
    console.error(error);
  };

  return (
    <EditPasswordContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <EditPasswordFormContainer>
          <EditPasswordTextField
            type={isShowPassword ? "text" : "password"}
            label={t("current_password")}
            placeholder={t("please_enter_your_current_password")}
            {...register("password")}
            maxLength={50}
            isError={!!errors.password}
            message={errors?.password?.message}
            endAdornment={
              <a onClick={onShowPassword}>
                <Icon
                  icon={
                    isShowPassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />

          <EditPasswordTextField
            type={isShowUpdatePassword ? "text" : "password"}
            label={t("new_password")}
            placeholder={t("pwd_combination_min_8_chars")}
            {...register("updatePassword")}
            maxLength={50}
            isError={!!errors.updatePassword}
            message={errors?.updatePassword?.message}
            endAdornment={
              <a onClick={onShowUpdatePassword}>
                <Icon
                  icon={
                    isShowUpdatePassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />

          <EditPasswordTextField
            type={isShowUpdateMatchPassword ? "text" : "password"}
            label={t("confirm_new_password")}
            placeholder={t("please_enter_the_same_password_again")}
            {...register("updateMatchPassword")}
            maxLength={50}
            isError={!!errors.updateMatchPassword}
            message={errors?.updateMatchPassword?.message}
            endAdornment={
              <a onClick={onShowUpdateMatchPassword}>
                <Icon
                  icon={
                    isShowUpdateMatchPassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />
        </EditPasswordFormContainer>

        <EditButtonContainer>
          <CancelButton
            type={"button"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={onEditCancel}
          >
            {t("cancel")}
          </CancelButton>
          <EditButton
            type={"submit"}
            styleType={BUTTON_TYPE.FILL}
            disabled={!isValid || updatePasswordMutation.isPending}
          >
            {t("modification_complete")}
          </EditButton>
        </EditButtonContainer>
      </form>
    </EditPasswordContainer>
  );
};

const EditPasswordContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    form {
      gap: 40px;
    }
  }
`;

const EditPasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
`;

const EditPasswordTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const EditButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const EditButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;
