import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { Radio } from "@shared/lib/components/common/atom/radio";
import { MultiLineTextField } from "@shared/lib/components/common/atom/text-feild/multi-line-text-field";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { logout } from "@/utils/auth";
import { useUserControllerWithdrawUser } from "@shared/generated/api/fn/kac/user/user";
import { useTranslation } from "react-i18next";

const MAX_ETC_REASON_LENGTH = 400;
const WITHDRAWAL_REASON_TYPE = {
  SERVICE_INCONVENIENCE: "SERVICE_INCONVENIENCE",
  DISSATISFACTION_WITH_LECTURE_CONTENT: "DISSATISFACTION_WITH_LECTURE_CONTENT",
  EXCESSIVE_NOTIFICATIONS: "EXCESSIVE_NOTIFICATIONS",
  ETC: "ETC",
} as const;

export type WithdrawalReasonType = keyof typeof WITHDRAWAL_REASON_TYPE;

const WITHDRAWAL_REASON_TYPE_MESSAGE = {
  [WITHDRAWAL_REASON_TYPE.SERVICE_INCONVENIENCE]: {
    type: WITHDRAWAL_REASON_TYPE.SERVICE_INCONVENIENCE,
    message: "service_inconvenience",
  },
  [WITHDRAWAL_REASON_TYPE.DISSATISFACTION_WITH_LECTURE_CONTENT]: {
    type: WITHDRAWAL_REASON_TYPE.DISSATISFACTION_WITH_LECTURE_CONTENT,
    message: "dissatisfaction_with_class_content",
  },
  [WITHDRAWAL_REASON_TYPE.EXCESSIVE_NOTIFICATIONS]: {
    type: WITHDRAWAL_REASON_TYPE.EXCESSIVE_NOTIFICATIONS,
    message: "excessive_ads_notifications",
  },
  [WITHDRAWAL_REASON_TYPE.ETC]: {
    type: WITHDRAWAL_REASON_TYPE.ETC,
    message: "other",
  },
};

const WITHDRAWAL_REASON_LIST = [
  WITHDRAWAL_REASON_TYPE_MESSAGE.SERVICE_INCONVENIENCE,
  WITHDRAWAL_REASON_TYPE_MESSAGE.DISSATISFACTION_WITH_LECTURE_CONTENT,
  WITHDRAWAL_REASON_TYPE_MESSAGE.EXCESSIVE_NOTIFICATIONS,
];

interface RequestWithdrawalForm {
  reason: string;
  etcReason?: string;
}

interface RequestWithdrawalProps {
  onEditCancel: () => void;
}

export const RequestWithdrawal = ({ onEditCancel }: RequestWithdrawalProps) => {
  const { t } = useTranslation();
  const schema = yup.object({
    reason: yup.string().trim().required(),
    etcReason: yup.string().when("reason", {
      is: WITHDRAWAL_REASON_TYPE.ETC,
      then: (schema) =>
        schema
          .max(MAX_ETC_REASON_LENGTH, t("up_to_400_characters_only"))
          .required(t("please_enter_other_reasons")),
    }),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<RequestWithdrawalForm>({
    resolver: yupResolver<RequestWithdrawalForm>(schema),
    mode: "onChange",
  });

  const reason = watch("reason");
  const onChangeReason = async (withdrawalReasonType: WithdrawalReasonType) => {
    setValue("reason", withdrawalReasonType);
    await trigger("reason");
  };

  const etcReason = watch("etcReason");

  const { accessToken } = useAccessToken();
  const userWithdrawalMutation = useUserControllerWithdrawUser({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: async () => {
        await logout({
          redirect: true,
        });
      },
      onError: (error) => {
        const { errorCode, message } = error.response?.data as any;

        openToast(TOAST_TYPE.ERROR, t("request_failed"));
      },
    },
  });

  const onSubmit: SubmitHandler<RequestWithdrawalForm> = async (data) => {
    const reasonType = data.reason as WithdrawalReasonType;
    const reasonMessage =
      reasonType === WITHDRAWAL_REASON_TYPE.ETC
        ? data.etcReason
        : WITHDRAWAL_REASON_TYPE_MESSAGE[reasonType].message;
    userWithdrawalMutation.mutate({
      data: {
        reason: reasonMessage || "",
      },
    });
  };

  const onError: SubmitErrorHandler<RequestWithdrawalForm> = async (error) => {
    console.error(error);
  };

  return (
    <RequestWithdrawalContainer>
      <ChecklistContainer>
        <ChecklistTitle>
          {t("things_to_check_before_withdrawal")}
        </ChecklistTitle>
        <ChecklistDescription>
          <ul>
            <li>
              {t("all_related_information_will_be_deleted_upon_withdrawal")}
            </li>
            <li>
              {t(
                "all_services_will_be_terminated_immediately_upon_withdrawal_and_no_refunds_will_be_provided",
              )}
            </li>
            <li>
              {t(
                "points_cash_will_be_forfeited_upon_withdrawal_and_no_refunds_will_be_provided",
              )}
            </li>
            <li>{t("i_have_reviewed_and_agree_to_the_above")}</li>
          </ul>
        </ChecklistDescription>
      </ChecklistContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <RequestWithdrawalFormContainer>
          <RequestWithdrawalFormTitle>
            {t("select_reason_for_withdrawal")}
          </RequestWithdrawalFormTitle>

          {WITHDRAWAL_REASON_LIST.map((withdrawalReason, index) => (
            <Radio
              key={`withdrawal-radio-${index}`}
              id={withdrawalReason.type}
              label={t(`${withdrawalReason.message}`)}
              checked={reason === withdrawalReason.type}
              onChange={() => onChangeReason(withdrawalReason.type)}
              value={withdrawalReason.type}
            />
          ))}

          <EtcRadioContainer>
            <FormRadioRowContainer>
              <Radio
                id={WITHDRAWAL_REASON_TYPE_MESSAGE.ETC.type}
                label={WITHDRAWAL_REASON_TYPE_MESSAGE.ETC.message}
                checked={reason === WITHDRAWAL_REASON_TYPE_MESSAGE.ETC.type}
                onChange={() =>
                  onChangeReason(WITHDRAWAL_REASON_TYPE_MESSAGE.ETC.type)
                }
                value={WITHDRAWAL_REASON_TYPE_MESSAGE.ETC.type}
              />
              <span>
                <b>{etcReason?.length || 0}</b>
                {`/${MAX_ETC_REASON_LENGTH}자`}
              </span>
            </FormRadioRowContainer>
            <EtcReasonContainer>
              <MultiLineTextField
                placeholder={t(
                  "we_would_appreciate_it_if_you_could_tell_us_the_reason",
                )}
                {...register("etcReason")}
                maxLength={MAX_ETC_REASON_LENGTH}
              />
            </EtcReasonContainer>
          </EtcRadioContainer>
        </RequestWithdrawalFormContainer>

        <RequestButtonContainer>
          <CancelButton
            type={"button"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={onEditCancel}
          >
            {t("cancel")}
          </CancelButton>
          <RequestButton
            type={"submit"}
            styleType={BUTTON_TYPE.FILL}
            disabled={!isValid}
          >
            {t("withdrawal_complete")}
          </RequestButton>
        </RequestButtonContainer>
      </form>
    </RequestWithdrawalContainer>
  );
};

const RequestWithdrawalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    form {
      gap: 40px;
    }
  }
`;

const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChecklistTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const ChecklistDescription = styled.div`
  border-radius: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray.gray95};

  ul {
    list-style: disc;
    padding-left: 28px;

    li {
      ${({ theme }) => theme.typography.body2.r};
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }
`;

const RequestWithdrawalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
`;

const RequestWithdrawalFormTitle = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const EtcRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormRadioRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.text.disabled.black};

    b {
      ${({ theme }) => theme.typography.body1.m};
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }
`;

const EtcReasonContainer = styled.div`
  padding-left: 28px;
`;

const RequestButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const RequestButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;
